body {
  background-color: #3a6ea5;
  font-family: arial;
  font-size: 15px;
}

::selection {
  background: none;
}

#browser-compatibility {
  width: 275px;
  text-align: center;
  background: #fff;
  margin-top: -58px;
  margin-left: -137px;
  display: none;
  position: absolute;
  top: 40%;
  left: 50%;
}

.about {
  color: #fff;
  z-index: -1;
  flex-direction: row;
  margin: 0;
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
}

@media (display-mode: fullscreen) {
  .about {
    display: none;
  }
}

.about.loaded, .about a {
  display: flex;
}

.about a svg {
  width: 16px;
  height: 16px;
  fill: #fff;
  margin: 10px 13px;
  transition: all .25s ease-out;
}

.about a:before {
  content: "";
  width: 42px;
  height: 2px;
  background-color: #fff0;
  transition: all .25s ease-out;
  position: absolute;
  top: 0;
}

.about a:hover:before {
  background-color: #fff;
  transition: all .25s ease-out;
}

.about a:hover svg {
  transition: all .25s ease-out;
  transform: scale(1.35);
}

#github-share {
  background-color: #2d3238;
}

#music-credits {
  background-color: #598dc5;
}

#butterchurn-share {
  background-size: 100%;
  display: none;
}

#app {
  z-index: -2;
  position: absolute;
  inset: 0;
}

#app #loading {
  color: #fff;
  text-align: center;
  height: 30px;
  margin: auto;
  font-size: 40px;
  position: absolute;
  inset: 0;
}

#app #loading .ellipsis-anim span {
  opacity: 0;
  animation: 1s infinite ellipsis-dot;
}

#app #loading .ellipsis-anim span:nth-child(1) {
  animation-delay: 0s;
}

#app #loading .ellipsis-anim span:nth-child(2) {
  animation-delay: .1s;
}

#app #loading .ellipsis-anim span:nth-child(3) {
  animation-delay: .2s;
}

@keyframes ellipsis-dot {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.desktop-icon {
  text-align: center;
  cursor: pointer;
  image-rendering: pixelated;
}

.desktop-icon-title {
  color: #fff;
  -webkit-font-smoothing: none;
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border: 1px dotted #0000;
  margin-top: 5px;
  font-family: MS Sans Serif, Segoe UI, sans-serif;
  font-size: 11px;
}

.desktop-icon.selected .desktop-icon-title {
  background-color: navy;
  border-color: #fff;
}

.desktop-icon.selected img {
  filter: brightness(.35) sepia() hue-rotate(148deg) saturate(10);
}

/*# sourceMappingURL=index.e77fb099.css.map */
